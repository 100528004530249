import { Tooltip } from 'react-tooltip'
import { App } from "../../../../models/app";

export default function Actions(app: App, showMoreInfo: Function) {
  return (
    <div className="flex flex-row justify-between w-100">
      <button
        onClick={() => showMoreInfo()}
        className={`px-4 py-2 border-solid border-2 rounded-md border-red-700 bg-white text-red-600 hover:bg-gray-100`}
        data-tooltip-id="infos-tooltip"
        data-tooltip-content="Plus d'informations"
      >Infos +
      </button>
      <a
        href={app.url}
        target="_blank"
        className="px-4 py-2 text-white rounded-md bg-red-600 hover:bg-red-700"
      >Accéder →
      </a>
      <Tooltip id="contact-tooltip" place='bottom' />
      <Tooltip id="infos-tooltip" place='bottom' />
    </div>
  )
}