export const RoleSeeAll: string = "Je veux voir tout Deeplab";

export default function Roles(roles: Set<string>, setRole: Function) {
  const renderRole = (role: string) => {
    let bgColor = "bg-lime-500 hover:bg-lime-600";
    if (role === RoleSeeAll) {
      bgColor = "bg-blue-500 hover:bg-blue-600";
    }
    return (
      <div
        key={role}
        onClick={() => { setRole(role) }}
        className={`relative flex justify-center items-center cursor-pointer mx-4 my-2 px-6 py-4 ${bgColor} drop-shadow-md hover:drop-shadow-xl  rounded-lg w-48 max-w-sm h-48 max-h-48`}
      >
        <div className="text-white text-xl text-center">
          {role}
        </div>
      </div>
    )
  };

  return (
    <div className="flex flex-row justify-center flex-wrap max-w-full px-16">
      <h1 className="py-6 w-full text-center text-black font-bold text-xl">Je suis...</h1>
      {Array.from(roles).map((role) => renderRole(role))}
    </div>
  )
}