import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { FilteringProcess } from "../../../models/filteringProcess";
import { Process, ProcessesCategory, ProcessesType } from '../../../models/processesCategories';
import CountIndicator from './countIndicator';

const categoriesTypes: ProcessesType[] = require('../../../iso-12207_categories_and_processes.json');

/**
 * 
 * @param processFilters The FilteringProcess[] used to show/hide the components depending on chosen processes.
 * @param setProcesses The function used to set the parameter processFilters
 * @returns Selection menu from \@szhsin/react-menu where selecting a process updates the list of FilteringProcess[] by toggling the attribute selected of the clicked process
 */
export default function ProcessFilters(processFilters: FilteringProcess[], setProcesses: Function) {

  const selectedProcessIds = processFilters.filter((f) => f.selected).map((f) => f.process.processId);

  const handleToggleProcess = (process: Process) => {
    const newFilteringProcesses = [...processFilters];
    const oldFilteringProcess = processFilters.find((f) => f.process === process);
    const oldFilteringProcessIndex = processFilters.findIndex((f) => f.process === process);
    newFilteringProcesses[oldFilteringProcessIndex].selected = !oldFilteringProcess?.selected;
    setProcesses(newFilteringProcesses);
  }

  const getProcessStyle = (process: Process, color: string) => {
    let isSelected = processFilters.find((f) => f.process === process)?.selected;
    return isSelected ? `bg-${color}-500 text-white hover:bg-${color}-600` : "bg-gray-300 text-white hover:bg-gray-400";
  }

  /**
   * Count the number of selected processes for this category of a type
   */
  const countIndicatorPerCategory = (category: ProcessesCategory): number => {
    let count: number = 0;
    for (let indexProcess = 0; indexProcess < category.categoryProcesses.length; indexProcess++) {
      const process = category.categoryProcesses[indexProcess];
      if (selectedProcessIds.includes(process.processId)) {
        count++;
      }
    }
    return count;
  }

  /**
   * Count the number of selected processes for this type
   */
  const countIndicatorPerType = (type: ProcessesType): number => {
    let count: number = 0;
    for (let indexCategory = 0; indexCategory < type.processesTypeCategories.length; indexCategory++) {
      count += countIndicatorPerCategory(type.processesTypeCategories[indexCategory]);
    }
    return count;
  }

  const resetFilters = () => {
    const newFilters = [...processFilters];
    for (let processIndex = 0; processIndex < newFilters.length; processIndex++) {
      const filter = newFilters[processIndex];
      filter.selected = false;
    }
    setProcesses(newFilters);
  }

  return (
    <div className="mx-10 flex flex-row p-2 items-center">
      <div className="flex flex-col text-black items-center">
        <span className='font-bold'>Filtrer par processus :</span>
        <span className='italic text-sm'>(norme ISO/CEI 12207)</span>
      </div>
      {
        [
          {
            type: categoriesTypes[0], /* For "Système" processes */
            color: "green"
          },
          {
            type: categoriesTypes[1], /* For "Logiciel" processes */
            color: "blue"
          },
        ].map(({ type, color }) => (
          <Menu
            key={type.processesTypeName}
            menuButton={
              <MenuButton className={`relative bg-${color}-500 hover:bg-${color}-600 text-white px-4 py-2 rounded-md max-h-10 mx-2 my-1 whitespace-nowrap`}>
                {type.processesTypeName}
                {CountIndicator(countIndicatorPerType(type))}
              </MenuButton>
            }
          >
            {type.processesTypeCategories.map((category) => (
              <SubMenu key={category.categoryName} label={<div>{category.categoryName}{CountIndicator(countIndicatorPerCategory(category))}</div>} className={"relative"}>
                {category.categoryProcesses.map((process) => (
                  <MenuItem
                    key={process.processName}
                    className={`px-4 py-2 whitespace-nowrap ${getProcessStyle(process, color)}`}
                    onClick={() => handleToggleProcess(process)}
                  >{process.processName}
                  </MenuItem>
                ))}

              </SubMenu>
            ))}
          </Menu>
        ))
      }
      {
        // Show a reset button if at least one process selected
        countIndicatorPerType(categoriesTypes[0]) > 0
          || countIndicatorPerType(categoriesTypes[1]) > 0
          ?
          <button
            onClick={() => resetFilters()}
            className={`mx-4 text-red-600 hover:text-red-700`}
          >Réinitialiser
          </button>
          : null
      }
    </div>
  )
}