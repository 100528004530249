

import { useContext } from "react";
import { AuthContext } from "../context/AuthContextProvider";
import Home from "./home";
import Loading from "./common/loading";

export default function ProtectedApp() {
  const authContext = useContext(AuthContext);

  if (!authContext.isAuthenticated) {
    return <Loading />;
  }
  else {
    return <main className="flex min-h-screen w-full flex-col bg-gray-50">
      <Home />
    </main>
  }
}