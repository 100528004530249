import { App } from "../../../../models/app";
import { getColorForProcessId, processNameById } from "../../../../lib/process";

export default function Popup(app: App | null, logo: string | undefined, onClose: Function) {
  if (app === null) return null;

  return (
    <div
      className="fixed top-0 left-0 bg-black/50 z-10 min-h-full min-w-full flex justify-center py-20"
      onClick={() => onClose()} // so it closes when clicking outside the Card
    >
      <div
        key={app.appName}
        className="relative flex flex-col text-black px-12 py-10 bg-slate-50 h-1/2 w-1/2 max-w-full max-h-full drop-shadow-2xl rounded-lg whitespace-nowrap"
        onClick={(e) => { e.stopPropagation(); }} // so it doesn't close when clicking inside the Card
      >
        <div className="flex flex-row">
          <div className="justify-center">
            <img
              src={logo}
              alt=""
              width={60}
              height={60}
            />
          </div>
          <div className="flex justify-center items-center px-4 text-xl font-bold">
            {app.appName}
          </div>
        </div>
        <div className="max-w-max text-wrap whitespace-normal py-4">
          {app.description_fr}
        </div>
        <div className="flex flex-row my-4">
          <span className="font-bold">Lien :&nbsp;</span>
          <span className="max-w-full	truncate">
            <a href={app.url} target="_blank" className="text-blue-600 hover:text-blue-700 visited:text-purple-600">
              {app.url}
            </a>
          </span>
        </div>
        <div className="flex flex-row my-4">
          <span className="font-bold">Documentation :&nbsp;</span>
          <span className="max-w-full	truncate">
            {app.documentationUrl
              ? <a href={app.documentationUrl} target="_blank" className="text-blue-600 hover:text-blue-700 visited:text-purple-600">
                {app.documentationUrl}
              </a>
              : "à venir"
            }

          </span>
        </div>
        {app.notes
          ? <div className="flex flex-row my-4">
            <span className="font-bold">Notes :&nbsp;</span>
            <span className="max-w-full	whitespace-normal">
              {app.notes}
            </span>
          </div>
          : null
        }
        <div className="flex flex-row my-4">
          <span className="font-bold">Salon Matrix-Element :&nbsp;</span>
          <span className="max-w-full truncate">
            {app.matrixElementRoomUrl
              ? <a href={app.matrixElementRoomUrl} target="_blank" className="text-blue-600 hover:text-blue-700 visited:text-purple-600">
                {app.matrixElementRoomUrl}
              </a>
              : "à venir"
            }

          </span>
        </div>
        <div className="flex flex-col my-4">
          <span className="font-bold">Contact :</span>
          <ul className="ml-12 list-disc">
            {app.contact.map((contact) => <li key={app.appName + contact.name}>{contact.name} : <span key={contact.name} className="italic">{contact.email}</span></li>)}
          </ul>
        </div>
        <div className="flex flex-row my-4 flex-wrap items-center">
          <span className="font-bold">Processus ISO 12207 :&nbsp;</span>
          {app.processesIds.map((id) => (<div
            key={id}
            className={`mx-2 my-1 px-4 py-2 text-white rounded-full bg-${getColorForProcessId(id)}-500 whitespace-nowrap`}
          >
            {processNameById[id]}
          </div>))}
          {app.processesIds.length === 0 ? 'aucun' : null}
        </div>
        <button
          onClick={() => onClose()}
          className={`mt-5 px-4 py-4 text-white rounded-md bg-red-500 hover:bg-red-600`}
        >Fermer
        </button>
      </div>
    </div>
  )
}