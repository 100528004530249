// Gitlab
const projectId = 1; // id for project https://gitlab.deeplab.fr/hugo.duret/deeplab_homepage
const fileUrl: string = `https://gitlab.deeplab.fr/api/v4/projects/${projectId}/repository/files/resources%2Fapplications_list.json/raw`;
const logoUrl = (appName: string) =>
  `https://gitlab.deeplab.fr/api/v4/projects/${projectId}/repository/files/resources%2Flogos%2F${appName}.png/raw`;
// const gitlabGetToken: string = "glpat-RyHmtj1S7Th58nsVBvi6"; // bot of project
const gitlabGetToken: string = "glpat-3ZatH42yhT7TCHzWRx8U"; // bot of project -- this is my token - christine.makasy

// TULEAP folder https://tuleap.deeplab.fr/plugins/document/deeplab-fab/folder/1016
/**
 * I tried with Tuleap but the problem is that the api doesn't let download files
 * API just gives info and metadata
 * The download/* url allow downloads but send a pre-flight OPTIONS which is blocked by CORS
 * Because we download from another domain it is blocked
 */
// const resourcesLogosFolderId = 1020; // id for folder https://tuleap.deeplab.fr/plugins/document/deeplab-fab/folder/1020
// const applicationsListFileUrl: string = `https://tuleap.deeplab.fr/plugins/docman/download/1019/1`;
// const logosFolderUrl: string = `https://tuleap.deeplab.fr/api/docman_items/${resourcesLogosFolderId}/docman_items`;
// const tuleapAccountToken: string = ""; // change to personal access token
// const getLogoUrl = (logoId: number) => `https://tuleap.deeplab.fr/plugins/docman/download/${logoId}/1`;

export default {
  fileUrl,
  logoUrl,
  gitlabGetToken,
};
