import { Process } from "./processesCategories";

export class FilteringProcess {
  process: Process;
  selected: boolean;

  constructor(process: Process, selected: boolean) {
    this.process = process;
    this.selected = selected;
  }
}
