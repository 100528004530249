import { App, AppStatus } from "../../../../models/app";

export default function StatusOverlay(app: App) {
  switch (app.status) {
    case AppStatus.En_Cours:
    case AppStatus.Planifiée:
      return (<div className="flex justify-center items-center z-10 w-full h-full absolute bottom-0 left-0 bg-gray-500/50 rounded-lg">
        <div className="text-white font-bold text-3xl -rotate-45">
          {app.status}
        </div>
      </div>);
    default:
      return null;
  }
}