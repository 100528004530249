import { Tooltip } from 'react-tooltip'
import { FilteringSensitivity } from "../../../models/filteringSensitivity";
import { AppSensitivity } from '../../../models/app';

/**
 * 
 * @param sensitivityFilters The FilteringSensitivity[] used to show/hide the components depending on chosen sensitivities.
 * @param setSensitivities The function used to set the parameter sensitivityFilters
 */
export default function SensitivityFilters(sensitivityFilters: FilteringSensitivity[], setSensitivities: Function) {

  const handleToggleSensitivity = (sensitivity: AppSensitivity) => {
    const newFilteringSensitivities = [...sensitivityFilters];
    const oldFilteringSensitivity = sensitivityFilters.find((f) => f.sensitivity === sensitivity);
    const oldFilteringSensitivityIndex = sensitivityFilters.findIndex((f) => f.sensitivity === oldFilteringSensitivity?.sensitivity);
    newFilteringSensitivities[oldFilteringSensitivityIndex].selected = !oldFilteringSensitivity?.selected;
    setSensitivities(newFilteringSensitivities);
  }

  const unselectedSensitivityStyle: { [key in AppSensitivity]: string } = {
    [AppSensitivity.Contenu_sensible_autorisé]: "border-green-700 bg-white text-green-600 hover:bg-gray-100",
    [AppSensitivity.Contenu_sensible_à_protéger]: "border-yellow-700 bg-white text-yellow-600 hover:bg-gray-100",
    [AppSensitivity.Pas_de_contenu_sensible]: "border-red-700 bg-white text-red-600 hover:bg-gray-100",
  }

  const selectedSensitivityStyle: { [key in AppSensitivity]: string } = {
    [AppSensitivity.Contenu_sensible_autorisé]: "border-white bg-green-600 text-white hover:bg-green-700",
    [AppSensitivity.Contenu_sensible_à_protéger]: "border-white bg-yellow-600 text-white hover:bg-yellow-700",
    [AppSensitivity.Pas_de_contenu_sensible]: "border-white bg-red-600 text-white hover:bg-red-700",
  }

  const getSensitivityStyle = (sensitivity: AppSensitivity) => {
    if (sensitivityFilters.find((f) => f.sensitivity === sensitivity)?.selected == true) {
      return selectedSensitivityStyle[sensitivity];
    }
    return unselectedSensitivityStyle[sensitivity];
  }

  return (
    <div className="mx-10 flex flex-row p-2 items-center">
      <div className="flex flex-col text-black items-center">
        <span className='font-bold'>Filtrer par sensibilité :</span>
      </div>
      <button
        onClick={() => handleToggleSensitivity(AppSensitivity.Contenu_sensible_autorisé)}
        className={`px-4 py-2 mx-2 border-solid border-2 rounded-md ${getSensitivityStyle(AppSensitivity.Contenu_sensible_autorisé)}`}
      >
        {AppSensitivity.Contenu_sensible_autorisé}
      </button>
      <button
        onClick={() => handleToggleSensitivity(AppSensitivity.Contenu_sensible_à_protéger)}
        className={`px-4 py-2 mx-2 border-solid border-2 rounded-md ${getSensitivityStyle(AppSensitivity.Contenu_sensible_à_protéger)}`}
        data-tooltip-id="AppSensitivity.Contenu_sensible_à_protéger"
        data-tooltip-content="Le contenu sensible doit être protégé dans un conteneur chiffré par Zed"
      >
        {AppSensitivity.Contenu_sensible_à_protéger}
        <Tooltip id="AppSensitivity.Contenu_sensible_à_protéger" place='bottom' />
      </button>
      <button
        onClick={() => handleToggleSensitivity(AppSensitivity.Pas_de_contenu_sensible)}
        className={`px-4 py-2 mx-2 border-solid border-2 rounded-md ${getSensitivityStyle(AppSensitivity.Pas_de_contenu_sensible)}`}
      >
        {AppSensitivity.Pas_de_contenu_sensible}
      </button>
    </div>
  )
}