import { Process, ProcessesType } from "../models/processesCategories";

const rawCategoriesTypes: ProcessesType[] = require('../iso-12207_categories_and_processes.json');
const uniqueProcesses: Set<Process> = new Set(rawCategoriesTypes.flatMap((type) => type.processesTypeCategories.flatMap((category) => category.categoryProcesses)));
// get list of process's name by process's id like [{"6.3.1": "Planification de projet"}, ...]
const processNameById: {
  [x: string]: string;
} = {};
const allProcess: Process[] = rawCategoriesTypes.flatMap((type) => type.processesTypeCategories.flatMap((category) => category.categoryProcesses));
for (let index = 0; index < allProcess.length; index++) {
  const process = allProcess[index];
  processNameById[process.processId] = process.processName;
}

/**
 * @param appProcessesIds processesIds of the app 
 * @returns true if at least one processId
 * of the app corresponds to an id of the processes of type "Systeme".
 * Returns false otherwise
 */
const doesAppHaveProcessOfTypeSysteme = (appProcessesIds: string[]) => {
  for (let index = 0; index < appProcessesIds.length; index++) {
    const processId = appProcessesIds[index];
    if (processId.startsWith("6.")) return true;
  }
}
/**
 * @param appProcessesIds processesIds of the app 
 * @returns true if at least one processId
 * of the app corresponds to an id of the processes of type "Logiciel".
 * Returns false otherwise
 */
const doesAppHaveProcessOfTypeLogiciel = (appProcessesIds: string[]) => {
  for (let index = 0; index < appProcessesIds.length; index++) {
    const processId = appProcessesIds[index];
    if (processId.startsWith("7.")) return true;
  }
}

const getProcessNameFromID = (processId: string): string => processNameById[processId];

const getColorForProcessId = (processId: string): string => {
  if (processId.startsWith("6.")) return "green";
  if (processId.startsWith("7.")) return "blue";
  return "black";
}

export {
  processNameById,
  uniqueProcesses,
  doesAppHaveProcessOfTypeSysteme,
  doesAppHaveProcessOfTypeLogiciel,
  getProcessNameFromID,
  getColorForProcessId
}