import { useContext } from "react";
import { AuthContext } from "../context/AuthContextProvider";

export default function TopBar() {
	const authContext = useContext(AuthContext);

	return (
		<div className="flex flex-col z-10 w-full bg-white drop-shadow-xl justify-between fixed">
			<div className="flex flex-col w-full bg-red-500 p-2 items-center justify-center">
				<p className="text-white font-bold">
					Attention : cette version de Deeplab sert au développement !
				</p>
				<p className="text-white">
					Les données sont stockées sur des serveurs OVH en France, en-dehors du réseau sécurisé CEA. Ne pas y mettre de données non publiques.
				</p>
				<p className="text-white">
					Les données sont stockées mais pas redondées. Elles peuvent être perdues en cas d'incident.
				</p>
			</div>
			<div className="flex flex-row p-5 justify-between">
				<div className="flex flex-row">
					<img
						src="/logos/cea_cropped.png"
						alt=""
						width={80}
						height={40}
					/>
					<img
						className="ml-5"
						src="/logos/deeplab.png"
						alt=""
						width={80}
						height={40}
					/>
				</div>
				<div className="flex flex-col text-black items-center">
					<div>Bienvenue sur Deeplab</div>
					<div className="font-bold ">{authContext.username}</div>
				</div>
			</div>
		</div>
	)
}

